import Vue from 'vue';
import VueRouter from 'vue-router';
import VueApp from './App.vue';
import VueI18n from 'vue-i18n';
import Vuex from 'vuex';
import Cookies from 'js-cookie';
import Messages from './language/index';
import SessionService from './service/SessionService';
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'

Vue.use(VueRouter);
Vue.use(VueMaterial);
Vue.use(VueI18n);
Vue.use(Vuex);
Vue.use(Cookies);

Vue.component('MdSelect', Vue.options.components.MdSelect.extend({
    methods: {
        isInvalidValue: function isInvalidValue () {
            return this.$el.validity ? this.$el.validity.badInput : this.$el.querySelector('input').validity.badInput
        }
    }
}))

import routes from './routes';
const router = new VueRouter(routes);

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requireLogin)) {
        if (SessionService.isSessionStarted()) {
            next();
            return;
        }
        next('/login');
    } else {
        next();
    }
});

const store = new Vuex.Store({
    state: {
        currentLanguage: Cookies.get('language') || 'de',
        sortingByCompany: Cookies.get('sortingByCompany') || false,
        currentTab: Cookies.get('currentTab') || 'locks',
        currentLockGroup: Cookies.get('currentLockGroup') || false,
        showInformation: Cookies.get('showInformation') || true,
    },
    mutations: {
        updateCurrentLanguage(state, newLanguage) {
            state.currentLanguage = newLanguage;
        },
        updateSortingByCompany(state, data) {
            state.sortingByCompany = data;
        },
        updateCurrentTab(state, data) {
            state.currentTab = data;
        },
        updateCurrentLockGroup(state, data) {
            state.currentLockGroup = data;
        },
        updateShowInformation(state, data) {
            state.showInformation = data;
        },
    },
    actions: {
        updateCurrentLanguage(context, newLanguage) {
            context.commit('updateCurrentLanguage', newLanguage);
            Cookies.set('language', newLanguage)
        },
        updateSortingByCompany(context, data) {
            context.commit('updateSortingByCompany', data);
            Cookies.set('sortingByCompany', data)
        },
        updateCurrentTab(context, data) {
            context.commit('updateCurrentTab', data);
            Cookies.set('currentTab', data)
        },
        updateCurrentLockGroup(context, data) {
            context.commit('updateCurrentLockGroup', data);
            Cookies.set('currentLockGroup', data)
        },
        updateShowInformation(context, data) {
            context.commit('updateShowInformation', data);
            Cookies.set('showInformation', data)
        }
    },
    getters: {
        currentLanguage(state) {
            return Cookies.get('language') || state.currentLanguage;
        },
        sortingByCompany() {
            let sortingCookies = Cookies.get('sortingByCompany') || "false";
            if (sortingCookies === "false") {
                return false
            } else {
                return true
            }
        },
        currentTab(state) {
            return Cookies.get('currentTab') || state.currentTab;
        },
        currentLockGroup(state){
            return Cookies.get('currentLockGroup') || state.currentLockGroup;
        },
        showInformation(state){
            return Cookies.get('showInformation') || state.showInformation;
        }
    },
});

const i18n = new VueI18n({
    locale: store.getters.currentLanguage,
    messages: Messages
});

new Vue({
    i18n,
    el: '#vue-application',
    store,
    router,
    render: h => h(VueApp)
});
