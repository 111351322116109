<template>
  <tr v-if="sortingByCompany || (!sortingByCompany && !user.type)" :class="backgroundRowClass(index,user.phone)" >
    <td v-if="user.type" class="company-name">{{this.companyName()}}</td>
    <td class="caption" v-if="!user.type" :class="{'textSelected':effectedUser()}" >
      <div v-if="user.phone === lastAddedUserPhoneNumber" id="newElement"></div>
      <div class="menuContent">
        <span class="userImageClass" v-on:click="emitToggleInfo()"><img src="../../assets/user.svg"/></span>
        <span class="userNameClass" v-on:click="emitToggleInfo()">{{this.parse_name(user)}}</span>
        <span class="infoClassCompany">{{user.company}}</span>
        <span class="infoClass">{{user.phone}}</span>
        <span>
          <div class="lineEditButton" v-on:click="emitStartEditUserMode(user)">
            <img src="../../assets/pen.svg" alt="edit" />
          </div>
          <div class="lineDeleteButton" v-on:click="emitOpenDeleteUserModal(user)">
            <img src="../../assets/delete.svg" alt="delete" />
          </div>
        </span>
      </div>
    </td>

    <td class="cell cellForGroups">
      <div class="pointer singleCell">
        <div class="wrapperIcon">
          <img v-if="user.id" v-on:click="changePermissionWholeRow()"
               :src="imageToShowWholeRow()"
          />
        </div>
      </div>
    </td>

    <td v-for="lock in lockObjects" :key="lock.id" class="cell" :class="{'cellForGroups':lock.type}" >
      <div class="pointer singleCell">
        <div class="wrapperIcon">
          <div v-if="hasAccessFromOtherManager(user.id, lock.id)" class="otherManagerIcon">
            <img @mouseover="updateTooltipText(user, lock)" src="../../assets/icon_normal_information.svg"/>
            <md-tooltip class="myTooltipClass" md-delay="300" v-if="hasAccessFromOtherManager(user.id, lock.id)" md-direction="top">
              {{tooltip}}
            </md-tooltip>
          </div>
          <img v-if="user.id" v-on:click="changePermission(lock.id)"
               :class="{'selected':itHasToChange(lock.id),'disabled':loading || tableLoading}"
               :src="imageToShow(lock.id)"
          />
        </div>
      </div>
    </td>

  </tr>
</template>

<script>
export default {
  name: 'userRow',
  props: ['user', 'index', 'userInForeground', 'lockObjects', 'lastAddedUserPhoneNumber', 'users', 'loading', 'tableLoading', 'tooltipText', 'sortingByCompany','locksGroupsFiltered'],
  data() {
    return {
      tempUser: false,
      tooltip: this.tooltipText,
      allRoomsColumnStatus: 100,
    }
  },
  created() {
    this.tempUser = JSON.parse(JSON.stringify(this.user))
  },
  mounted(){
    if (!this.tempUser.type) {
      //avoid calls for company label
      this.updateAllRoomsStatus();
    }
  },
  methods: {
    companyName(){
      if(!this.user.company || this.user.company === ''){
        return this.$i18n.t('HOME.INVITATION_SENT')
      }
      return this.user.company
    },
    changePermission(lockId) {

      if(this.loading || this.tableLoading) {
        return;
      }

      let lock = this.tempUser.locks.find(l => l.id === lockId);

      let change = {
        "id": this.user.id
      }
      if (lock === undefined){
        let newLockInfo = {
          "id": lockId,
          "can_access": true,
          "can_manage": false
        };
        change.lock = newLockInfo
        this.tempUser.locks.push(newLockInfo)
      } else if (lock.can_access === true && lock.can_manage === false) {
        lock.can_manage = true;
        change.lock = JSON.parse(JSON.stringify(lock))
      } else if (lock.can_access === true && lock.can_manage === true) {
        lock.can_access = false;
        lock.can_manage = false;
        change.lock = {
          "id": lockId,
          "can_access": false,
          "can_manage": false
        };
      } else if (lock.can_access === false && lock.can_manage === false) {
        lock.can_access = true;
        change.lock = {
          "id": lockId,
          "can_access": true,
          "can_manage": false
        };
      }
      this.updateAllRoomsStatus()
      this.emitUpdateListOfChanges(change)
    },
    updateAllRoomsStatus () {
      let canAccessArray = [];
      let canManageArray = [];
      if(this.tempUser.locks){
        this.tempUser.locks.forEach(lockItem => {
          canAccessArray.push(lockItem.can_access)
          canManageArray.push(lockItem.can_manage)
        })
      }
      if(this.tempUser.lock_groups){
        this.tempUser.lock_groups.forEach(group => {
          if(group && group.access){
            if (group.access === 'admin'){
              canAccessArray.push(true)
              canManageArray.push(true)
            }
            if (group.access === 'access'){
              canAccessArray.push(true)
              canManageArray.push(false)
            }
          }
        })
      }
      if (canAccessArray.length === 0) {
        this.allRoomsColumnStatus = 0
        // if(this.locksGroupsFiltered.length > 0){
        //   this.allRoomsColumnStatus = 100
        // } else {
        //   this.allRoomsColumnStatus = 0
        // }
      } else {
        if(canAccessArray.every( (val, i, arr) => val === arr[0] ) && canManageArray.every( (val, i, arr) => val === arr[0] )){
          if(canAccessArray.length < this.lockObjects.length){
            if(canAccessArray[0] === false && canManageArray[0] === false){
              this.allRoomsColumnStatus = 0
            } else {
              this.allRoomsColumnStatus = 100
            }
          } else {
            if(canAccessArray[0] === true && canManageArray[0] === true){
              this.allRoomsColumnStatus = 2
            } else if (canAccessArray[0] === true && canManageArray[0] === false) {
              this.allRoomsColumnStatus = 1
            } else {
              this.allRoomsColumnStatus = 0
            }
          }
        } else {
          this.allRoomsColumnStatus = 100
        }
      }

    },
    changePermissionWholeRow(){
      if(this.loading || this.tableLoading) {
        return;
      }

      let access
      let manage

      if (this.allRoomsColumnStatus === 0 || this.allRoomsColumnStatus === 100){
        this.allRoomsColumnStatus = 1
        access = true;
        manage = false;

      } else if(this.allRoomsColumnStatus === 1) {
        this.allRoomsColumnStatus = 2
        access = true;
        manage = true;

      } else if(this.allRoomsColumnStatus === 2) {
        this.allRoomsColumnStatus = 0
        access = false;
        manage = false;
      }

      this.lockObjects.forEach(lockItem => {

        let newLockInfo = {
          "id": lockItem.id,
          "can_access": access,
          "can_manage": manage
        };

        let change = {
          "id": this.user.id,
          "lock": newLockInfo
        };

        let lock = this.tempUser.locks.find(l => l.id === lockItem.id);

        if (lock === undefined) {
          this.tempUser.locks.push(JSON.parse(JSON.stringify(newLockInfo)))
        } else {
          if (lock.can_access !== access) {
            lock.can_access = access;
          }
          if (lock.can_manage !== manage) {
            lock.can_manage = manage;
          }
        }
        this.emitUpdateListOfChanges(change);
      });
    },

    emitUpdateListOfChanges(change){
      this.$emit('updateListOfChanges', change)
    },
    emitOpenDeleteUserModal(user){
      this.$emit('openDeleteUserModal', user)
    },
    emitStartEditUserMode(user){
      this.$emit('startEditUserMode', user)
    },
    emitToggleInfo(){
      this.$emit('toggleInfo')
    },

    backgroundRowClass(index, phone) {
      if (index % 2 !== 0) {
        if (phone === this.lastAddedUserPhoneNumber) {
          return 'highLightEvenRow'
        }
        return 'evenRow'
      } else {
        if (phone === this.lastAddedUserPhoneNumber) {
          return 'highLightRow'
        }
        return 'oddRow'
      }
    },
    backgroundButtonsClass(index, phone) {
      if (index % 2 !== 0) {
        if (!this.lastAddedUserPhoneNumber) {
          return 'bgEven'
        }
        if (phone === this.lastAddedUserPhoneNumber) {
          return 'highLightEvenRow'
        }
        return 'evenRow'
      } else {
        if (!this.lastAddedUserPhoneNumber) {
          return 'bgLight'
        }
        if (phone === this.lastAddedUserPhoneNumber) {
          return 'highLightRow'
        }
      }
    },
    hasAccessFromOtherManager: function (userId, lockId) {
      if(userId === undefined){
        return false
      }
      let user = this.users.find(el => el.id === userId);
      let lock = user.locks.find(l => l.id === lockId);

      return (lock && lock.other_managers && lock.other_managers.length);
    },
    effectedUser: function () {
      let effected = false
      if(this.tempUser && this.tempUser.locks) {
        this.tempUser.locks.forEach(l => {
          if (this.itHasToChange(l.id)) {
            effected = true
          }
        })
      }
      return effected
    },
    imageToShowWholeRow: function(){
      if(this.allRoomsColumnStatus === 0){
        return require("../../assets/no_access.svg");
      } else if(this.allRoomsColumnStatus === 1){
        return require("../../assets/access.svg");
      } else if(this.allRoomsColumnStatus === 2){
        return require("../../assets/admin.svg");
      } else if(this.allRoomsColumnStatus === 100){
        return require("../../assets/mixed.svg");
      }
    },

    imageToShow: function (lockId) {
      if (lockId === undefined || lockId === null || !this.tempUser) {return null}
      let lock = this.tempUser.locks.find(l => l.id === lockId);
      if (lockId.charAt(0).toLowerCase() === 'g') {

        if (!this.itHasToChange(lockId)){

          let group = false
          if(this.tempUser.lock_groups){
            group = this.tempUser.lock_groups.find(l => ('g'+l.id) === lockId);
          }

          if(group){
            if(group.access === 'admin'){
              return require("../../assets/admin.svg");
            }
            if(group.access === 'access'){
              return require("../../assets/access.svg");
            }
            if(group.access === 'mixed'){
              return require("../../assets/mixed.svg");
            }
          }
        }
      }
      if (lock === undefined || lock.can_access === false) {
        if (this.itHasToChange(lockId)){
          return require("../../assets/no_access_selected.svg");
        }
        return require("../../assets/no_access.svg");
      }
      if (lock.can_manage === true) {
        if (this.itHasToChange(lockId)){
          return require("../../assets/admin_selected.svg");
        }
        return require("../../assets/admin.svg");
      }
      if (lock.can_manage === false) {
        if (this.itHasToChange(lockId)){
          return require("../../assets/access_selected.svg");
        }
        return require("../../assets/access.svg");
      }
    },

    itHasToChange: function (lockId) {

      if(!this.tempUser){
        return false
      }
      if (lockId === undefined || !this.user.locks) {
        return false
      }
      let lock = this.user.locks.find(l => l.id === lockId);
      let tempLock = this.tempUser.locks.find(l => l.id === lockId);
      if (lock === undefined) {
        lock = ''
      }
      if (tempLock === undefined) {
        tempLock = ''
      }
      //lock groups after being selected remains to change
      if (tempLock !== '' && tempLock.id.charAt(0).toLowerCase() !== 'g') {
        if(tempLock.can_access === false && lock === '') {
          return false
        }
      }
      return (JSON.stringify(lock) !== JSON.stringify(tempLock))
    },
    updateTooltipText: function(user, lock){
      let otherManagersList = ''

      let userObj = this.users.find(el => el.id === user.id);
      let lockObj = userObj.locks.find(l => l.id === lock.id);

      if (lockObj && lockObj.other_managers) {
        if (lockObj.other_managers.length > 0){
          lockObj.other_managers.forEach((o)=>{
            otherManagersList = otherManagersList + o.first_name + ' ' + o.last_name + ' '
          })
        }
      }

      this.tooltip = this.$i18n.t('HOME.MESSAGES.TOOLTIP_RIGHTS_FROM_OTHERS', {
        username: user.name,
        lockname: lock.name,
        otherManagersList: otherManagersList
      });

    },
    parse_name: function (usr) {
      let parsed_name = usr.last_name;

      if (usr.first_name) {
        parsed_name = parsed_name + ' ' + usr.first_name
      }

      if (parsed_name === '') {
        parsed_name = usr.phone
      }

      return parsed_name;
    },
  }
}

</script>

<style scoped>
@keyframes highLightRow {
  from {background-color: #E5DCCF}
  to {background-color: white}
}

@keyframes highLightEvenRow {
  from {background-color: #E5DCCF}
  to {background-color: #faf8f5}
}
.highLightRow td{
  animation-name: highLightRow;
  animation-duration: 7s;
  background-color: white
}

.highLightEvenRow td{
  animation-name: highLightEvenRow;
  animation-duration: 7s;
  background-color: #faf8f5
}
.company-name{
  border-right: 1px solid #c2a977;
  text-align: left;
  padding-left: 30px;
  position: sticky;
  left: 0;
  min-width: 260px;
  max-width: 260px;
  overflow: hidden;
  z-index: 1;
  height: 70px;
  font-weight: bold;
  font-size: 16px;
}
.caption {
  border-right: 1px solid #c2a977;
  text-align: left;
  padding: 0.25rem;
  position: sticky;
  left: 0;
  min-width: 260px;
  max-width: 260px;
  overflow: hidden;
  z-index: 1;
}

.evenRow td{
  background-color: #faf8f5;
}
.oddRow td{
  background-color: white;
}

.lineEditButton {
  padding-top: 4px;
  padding-left: 60px;
  display: inline-block;
  cursor: pointer;
}
.lineDeleteButton {
  padding-top: 4px;
  padding-left: 20px;
  display: inline-block;
  cursor: pointer;
}

.menuContent {
  min-width: 750px;
}
.userNameClass {
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 10px;
  text-overflow: ellipsis;
  width: 196px;
  float: left;
  overflow: hidden;
  font-size: 16px;
  cursor: pointer;
}
.userImageClass {
  padding-left: 30px;
  float: left;
  width: 68px;
  cursor: pointer;
}
.infoClass {
  width: 150px;
  display: block;
  float: left;
  margin-left: 20px;
  padding-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
}
.infoClassCompany {
  width: 180px;
  display: block;
  float: left;
  margin-left: 20px;
  padding-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
}
.cell {
  text-align: center;
}
.cellForGroups {
  border-right: 1px dashed #E1D4BB;
}
.singleCell {
  margin: auto;
  width: 64px;
  padding: 16px 4px 16px 4px;
}
.wrapperIcon {
  position: relative;
  min-height: 50px;
}
.cellForGroups .singleCell{
  background-color: #F0ECE5;
}
.pointer {
  cursor: pointer;
}
.myTooltipClass {
  color: #000;
  background-color: #E9E2D6;
  border-radius: 8px;
  font-size: 16px;
  font-family: Mulish;
  padding: 8px 15px 30px 15px;
}
.otherManagerIcon {
  position: absolute;
  width: 24px;
  right: -10px;
  top: 5px;
}
table td {
  background: #fff;
}
.textSelected {
  color: #c2a977;
}
.selected {
  margin-top: -2px;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
td { padding: 0 }
</style>