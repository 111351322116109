<template>
  <tr :class="backgroundRowClass(index)" >
    <td class="caption">
      <div class="menuContent">
        <span class="userImageClass" v-on:click="emitToggleInfo()"><img src="../../assets/trasponder.svg"/></span>
        <span class="userNameClass" v-on:click="emitToggleInfo()">
          {{card.note}}
          <md-tooltip v-if="card.note.length > 20" class="myTooltipClass" md-delay="500" md-direction="top">
            {{card.note}}
          </md-tooltip>
        </span>

        <span class="firstInfoClass">{{card.company}}</span>
        <span class="secondInfoClass">{{card.nfc_card_id}}</span>
        <span class="thirdInfoClass">{{card.valid_to}}</span>
        <span class="lineEditButton" v-on:click="emitStartEditCardMode(card)">
          <img src="../../assets/pen.svg" alt="edit" />
        </span>
        <span class="lineDeleteButton" v-on:click="emitOpenDeleteCardModal(card)">
          <img src="../../assets/delete.svg" alt="delete" />
        </span>
      </div>
    </td>

    <td v-if="lockObjects.length > 1" class="cell allRooms ">
      <div class="singleCell">
        <div class="wrapperIcon" v-if="card.id" v-on:click="debug()">
          <img :src="imageToShowWholeRow()" />
        </div>
      </div>
    </td>

    <td v-for="lock in lockObjects" :key="lock.id" class="cell">
      <div class="singleCell">
        <div class="wrapperIcon">
          <img v-if="lock.id"
               class="disabled"
               :src="imageToShow(lock.id)"
          />
        </div>
      </div>
    </td>

  </tr>
</template>

<script>
export default {
  name: 'cardRow',
  props: ['card', 'index', 'lockObjects', 'lastAddedUserPhoneNumber', 'loading', 'tableLoading', 'tooltipText'],
  data() {
    return {
      tooltip: this.tooltipText,
      allRoomsColumnStatus: 100,
    }
  },
  created() {
  },
  mounted(){
    if(this.card.grantList.length < 1){
      this.allRoomsColumnStatus = 0;
    } else if(this.card.grantList.length === this.lockObjects.length){
      this.allRoomsColumnStatus = 1;
    }
  },
  methods: {
    debug(){
      console.log(JSON.stringify(this.card))
    },
    emitOpenDeleteCardModal(card){
      this.$emit('openDeleteCardModal', card)
    },
    emitStartEditCardMode(card){
      this.$emit('startEditCardMode', card)
    },
    emitToggleInfo(){
      this.$emit('toggleInfo')
    },

    backgroundRowClass(index) {
      if (index % 2 !== 0) {
        return 'evenRow'
      }
    },
    backgroundButtonsClass(index, phone) {
      if (index % 2 !== 0) {
        if (!this.lastAddedUserPhoneNumber) {
          return 'bgEven'
        }
        if (phone === this.lastAddedUserPhoneNumber) {
          return 'highLightEvenRow'
        }
        return 'evenRow'
      } else {
        if (!this.lastAddedUserPhoneNumber) {
          return 'bgLight'
        }
        if (phone === this.lastAddedUserPhoneNumber) {
          return 'highLightRow'
        }
      }
    },
    imageToShowWholeRow: function(){
      if(this.allRoomsColumnStatus === 0){
        return require("../../assets/no_access.svg");
      } else if(this.allRoomsColumnStatus === 1){
        return require("../../assets/access.svg");
      } else if(this.allRoomsColumnStatus === 2){
        return require("../../assets/admin.svg");
      } else if(this.allRoomsColumnStatus === 100){
        return require("../../assets/mixed.svg");
      }
    },

    imageToShow: function (lockId) {
      if (lockId === undefined) {return null}
      let lock = this.card.grantList.find(l => l.id === lockId);
      if (lock === undefined) {
        return require("../../assets/no_access.svg");
      } else {
        return require("../../assets/access.svg");
      }
    },
  }
}

</script>

<style scoped>
@keyframes highLightRow {
  from {background-color: #E5DCCF}
  to {background-color: white}
}

@keyframes highLightEvenRow {
  from {background-color: #E5DCCF}
  to {background-color: #faf8f5}
}
.highLightRow td{
  animation-name: highLightRow;
  animation-duration: 7s;
  background-color: white
}

.highLightEvenRow td{
  animation-name: highLightEvenRow;
  animation-duration: 7s;
  background-color: #faf8f5
}
.company-name{
  border-right: 1px solid #c2a977;
  text-align: left;
  padding-left: 30px;
  position: sticky;
  left: 0;
  min-width: 260px;
  max-width: 260px;
  overflow: hidden;
  z-index: 1;
  height: 70px;
  font-weight: bold;
  font-size: 16px;
}
.caption {
  border-right: 1px solid #c2a977;
  text-align: left;
  padding: 0.25rem;
  position: sticky;
  left: 0;
  min-width: 260px;
  max-width: 260px;
  overflow: hidden;
  z-index: 1;
}

.evenRow td{
  background-color: #faf8f5;
}

.lineDeleteButton {
  padding-top: 4px;
  padding-left: 90px;
  display: inline-block;
  cursor: pointer;
}

.menuContent {
  min-width: 800px;
}
.userNameClass {
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 10px;
  text-overflow: ellipsis;
  width: 196px;
  float: left;
  overflow: hidden;
  font-size: 16px;
  cursor: pointer;
}
.userImageClass {
  padding-left: 30px;
  float: left;
  width: 68px;
  cursor: pointer;
}
.firstInfoClass {
  width: 90px;
  display: block;
  float: left;
  margin-left: 20px;
  padding-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
}
.secondInfoClass {
  width: 160px;
  display: block;
  float: left;
  margin-left: 20px;
  padding-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
}
.thirdInfoClass {
   width: 120px;
   display: block;
   float: left;
   margin-left: 20px;
   padding-top: 10px;
   overflow: hidden;
   text-overflow: ellipsis;
   font-size: 16px;
 }
.lineEditButton {
  padding-top: 4px;
  padding-left: 10px;
  display: inline-block;
  cursor: pointer;
}
.lineDeleteButton {
  padding-top: 4px;
  padding-left: 10px;
  display: inline-block;
  flex-shrink: 0;
  cursor: pointer;
}

.cell {
  text-align: center;
}
.allRooms {
  border-right: 1px dashed #E1D4BB;
}
.singleCell {
  margin: auto;
  width: 64px;
}
.pointer {
  cursor: pointer;
}
.wrapperIcon {
  position: relative;
  filter: grayscale(60%);
}
.myTooltipClass {
  color: #000;
  background-color: #E9E2D6;
  border-radius: 8px;
  font-size: 16px;
  font-family: Mulish;
  padding: 8px 15px 30px 15px;
}
.otherManagerIcon {
  position: absolute;
  width: 24px;
  right: -10px;
  top: 5px;
}
table td {
  background: #fff;
  padding: 16px 4px 16px 4px;
}
.textSelected {
  color: #c2a977;
}
.selected {
  margin-top: -2px;
}
</style>